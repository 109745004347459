//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//SHOPS
export const GET_SHOPS = "/shops"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

export const MODEL_URL = "/project-apis/";
export const MY_MODEL_URL = "/my-project-apis/";

export const PROJECT_URL = "/projects/";
export const MY_PROJECT_URL = "/my-projects/";

export const APIS_URL = "/apis/";
export const POLICY_URL = "/billing/policy/";
export const USER_URL = "/user/";
export const ME_URL = "/profile/";

export const OPERATOR_URL = "/operator/";
export const BILLING_URL = "/billing/payment-method/";
export const PAYMENTHISTORY_URL = "/billing/payment-history/";
export const API_URL_LIST_URL = "/api-urls/";
export const PAYMENT_TERM_TYPE_URL = "/billing/payment-term-type/";
export const MEMBERSHIP_TYPE_URL = "/billing/membership-type/";
export const MY_MEMBERSHIP_TYPE_URL = "/billing/my-memberships/";
export const PAYMENT_TERM_URL = "/billing/payment-terms/";
export const MEMBERSHIP_URL = "/billing/memberships/";
export const JOIN_URL = '/join-project/';
export const INVITATION_URL = '/invitation/';
export const GROUP_URL = '/groups/';
export const TESTIMONIAL_URL = '/content/testimonials/';
export const FACE_URL = '/content/faces/';
export const FEATURE_URL = '/content/features/';

export const STATISTICS_PROJECT_URL = "/project-statistic/";
export const STATISTICS_API_URL = "/api-statistic/";
export const STATISTICS_MODEL_URL = "/model-statistic/";
export const STATISTICS_SUMMARY_URL = "/summary-statistic";