// @flow
import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_SIDEBAR_THEME,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	TOGGLE_RIGHT_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	CHANGE_PRELOADER,
	HIDE_RIGHT_SIDEBAR,
	SET_TOUR_OBJ, SET_PROJECT_TOUR_OBJ, SET_MODEL_TOUR_OBJ, SET_PAYMENT_TOUR_OBJ, SET_TOUR_PERCENT,
	SET_DOCMENT, SET_TOUR_STATE, SET_TO_PROJECT_TOUR_OBJ, SET_TO_MODEL_TOUR_OBJ
} from "./actionTypes";

const INIT_STATE = {
	layoutType: "vertical",
	layoutWidth: "fluid",
	leftSideBarTheme: "dark",
	leftSideBarType: "default",
	topbarTheme: "light",
	isPreloader: false,
	showRightSidebar: false,
	isMobile: false,
	tourobj: null,
	paymenttourobj: null,
	projecttourobj: null,
	modeltourobj: null,
	tourtoprojecttourobj: null,
	tourtomodeltourobj: null,
	tourpercent: 0,
	tourstate: {},
	document: "..."
};

const Layout = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_DOCMENT:
			return {
				...state,
				document: action.payload
			};
		case SET_TOUR_PERCENT:
			return {
				...state,
				tourpercent: action.payload
			};
		case SET_TOUR_STATE:
			return {
				...state,
				tourstate: action.payload
			};
		case SET_TOUR_OBJ:
			return {
				...state,
				tourobj: action.payload
			};
		case SET_PROJECT_TOUR_OBJ:
			return {
				...state,
				projecttourobj: action.payload
			};
		case SET_MODEL_TOUR_OBJ:
			return {
				...state,
				modeltourobj: action.payload
			};
		case SET_PAYMENT_TOUR_OBJ:
			return {
				...state,
				paymenttourobj: action.payload
			};
		case SET_TO_PROJECT_TOUR_OBJ:
			return {
				...state,
				tourtoprojecttourobj: action.payload
			};
		case SET_TO_MODEL_TOUR_OBJ:
			return {
				...state,
				tourtomodeltourobj: action.payload
			};
		case CHANGE_LAYOUT:
			return {
				...state,
				layoutType: action.payload
			};
		case CHANGE_PRELOADER:
			return {
				...state,
				isPreloader: action.payload
			};

		case CHANGE_LAYOUT_WIDTH:
			return {
				...state,
				layoutWidth: action.payload.width
			};
		case CHANGE_SIDEBAR_THEME:
			return {
				...state,
				leftSideBarTheme: action.payload.theme
			};
		case CHANGE_SIDEBAR_TYPE:
			return {
				...state,
				leftSideBarType: action.payload.sidebarType
			};
		case CHANGE_TOPBAR_THEME:
			return {
				...state,
				topbarTheme: action.payload
			};
		case TOGGLE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: !state.showRightSidebar
			};
		case SHOW_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: true
			};
		case HIDE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: false
			};
		default:
			return state;
	}
};

export default Layout;
