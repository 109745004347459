import React, { Component } from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Progress, Row, Col} from 'reactstrap';
import user from '../../../assets/images/users/user.png';
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import {
    alert3,
    capitalize,
    confirmInitialStep,
    confirmNextStep, getLoggedInUser, gettourpercent, getTourState, getTourStateValue,
    goNextStep,
    parseEmail, private_get,
    putTourState, timeout
} from "../../../helpers/fackBackend_Helper";
import {settourpercent, settourstate} from "../../../store/layout/actions";
import {ME_URL} from "../../../helpers/url_helper";
import Hotspot from "../../Common/hotspot";

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            tour: false,
        };
        this.toggle = this.toggle.bind(this);
        this.toggletour = this.toggletour.bind(this);
    }

    select_profile_menu(url) {
        this.props.history.push(url);
    }

    async toggletour() {
        this.setState(prevState => ({
            tour: !prevState.tour
        }));
        if(!this.state.tour){
            await timeout(300);

            if (getTourState(1)){
                confirmNextStep(1);
                goNextStep(this.props.tourobj, 1);
            }

            if (getTourState(5)){
                confirmNextStep(5);
                goNextStep(this.props.tourtoprojecttourobj, 5);
            }
            if (getTourState(6)){
                confirmNextStep(6);
                goNextStep(this.props.tourtomodeltourobj, 6);
            }

        }
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    startpaymenttour() {
        const user = getLoggedInUser();
        if (user.has_credit === true) {
            alert3("You have already added payment method!", "warning");return;
        }
        if (this.props.history.location.pathname === '/dashboard') {
            this.props.history.push('/dashboard');
            this.setState({
                tour: false
            });

            confirmInitialStep(2);
            goNextStep(this.props.paymenttourobj, 2);
        } else {
            confirmInitialStep(2);
            this.props.history.push('/dashboard');
        }
    }

    startprojecttour() {
        if (this.props.history.location.pathname === '/dashboard') {
            this.props.history.push('/dashboard');
            this.setState({
                tour: false
            });

            confirmInitialStep(3);
            goNextStep(this.props.projecttourobj, 3);
        } else {
            confirmInitialStep(3);
            this.props.history.push('/dashboard');
        }
    }
    startmodeltour() {
        if (this.props.history.location.pathname === '/dashboard') {
            this.props.history.push('/dashboard');
            this.setState({
                tour: false
            });

            confirmInitialStep(4);
            goNextStep(this.props.modeltourobj, 4);
        } else {
            confirmInitialStep(4);
            this.props.history.push('/dashboard');
        }
    }
    async componentDidMount() {
        const user = getLoggedInUser();
        const url = ME_URL + user.id + '/';
        const user_info = await private_get(url);
        var tour = user_info.tour;

        var tour_state = {};
        if (tour !== '') {
            tour_state = tour;
        }
        this.props.settourpercent(gettourpercent(tour_state));
        var _tour_state = getTourStateValue(tour_state);
        this.props.settourstate(_tour_state);

    }

    render() {

        let username = "";
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            // const uNm = obj.email.split("@")[0];

            username = obj.firstname + " " + obj.lastname;
        }


        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.tour} toggle={this.toggletour} className="onboarding-s d-inline-block user-dropdown tour">
                    <DropdownToggle tag="button" className="btn header-item waves-effect">
                        {this.props.tourpercent !== 100 &&
                            <div className="ux_hotspot_d">
                                <Hotspot/>
                            </div>
                        }
                        <i id="ux_ques" className="ri-question-fill d-xl-inline-block font-size-30"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <div>
                            <Card className="tourcard">
                                <CardBody>
                                    <h5 className="tour-title font-size-13 mt-3">Touring Completion Status <span>{this.props.tourpercent}%</span></h5>
                                    <div>
                                        <Progress animated className="mt-3" color="primary" value={this.props.tourpercent}>{this.props.tourpercent}%</Progress>
                                    </div>
                                    <div className="mt-4">
                                        <Row className="mb-2">
                                            {this.props.tourstate.tour_state_step1 === false &&
                                                <Col xs={8} className="d-flex align-items-center">
                                                <i className="font-size-15 ri-checkbox-blank-circle-line purple"></i><span title="Add Payment Method" className="text-truncate tour-title pl-2 font-size-13"> Add payment method</span>
                                            </Col>
                                            }
                                            {this.props.tourstate.tour_state_step1 === true &&
                                                <Col xs={8} className="d-flex align-items-center">
                                                <i className="font-size-15 ri-checkbox-circle-fill purple"></i><span title="Add Payment Method" className="text-truncate tour-title pl-2 font-size-13"> Add payment method</span>
                                            </Col>
                                            }
                                            {this.props.tourstate.tour_state_step1 === false &&
                                            <Col xs={4} className='d-flex align-items-center justify-content-end'>
                                                <a onClick={this.startpaymenttour.bind(this)} id="ux_start_payment_tour"
                                                   className="float-right hand font-size-13">Start Tour!</a>
                                            </Col>
                                            }
                                            {this.props.tourstate.tour_state_step1 === true &&
                                            <Col xs={4} className='d-flex align-items-center justify-content-end'>
                                                <a id="ux_start_payment_tour"
                                                   className="disabled_gray float-right hand font-size-13">Start Tour!</a>
                                            </Col>
                                            }
                                        </Row>
                                        <Row className="mb-2">
                                            {this.props.tourstate.tour_state_step2 === false &&
                                                <Col xs={8} className="d-flex align-items-center">
                                                    <i className="font-size-15 ri-checkbox-blank-circle-line purple"></i><span title="Create your first project and get oauth tokens" className="text-truncate tour-title pl-2 font-size-13"> Create your first project and get oauth tokens</span>
                                                </Col>
                                            }
                                            {this.props.tourstate.tour_state_step2 === true &&
                                                <Col xs={8} className="d-flex align-items-center">
                                                    <i className="font-size-15 ri-checkbox-circle-fill purple"></i><span title="Create your first project and get oauth tokens" className="text-truncate tour-title pl-2 font-size-13"> Create your first project and get oauth tokens</span>
                                                </Col>
                                            }
                                            <Col xs={4} className='d-flex align-items-center justify-content-end'>
                                                <a id="ux_start_project_tour" onClick={this.startprojecttour.bind(this)} className="float-right hand font-size-13">Start Tour!</a>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            {this.props.tourstate.tour_state_step3 === false &&
                                                <Col xs={8} className="d-flex align-items-center">
                                                    <i className="font-size-15  ri-checkbox-blank-circle-line purple"></i><span title="Create your first model and get the API endpoint" className="text-truncate tour-title pl-2 font-size-13"> Create your first model and get the API endpoint</span>
                                                </Col>
                                            }
                                            {this.props.tourstate.tour_state_step3 === true &&
                                                <Col xs={8} className="d-flex align-items-center">
                                                    <i className="font-size-15  ri-checkbox-circle-fill purple"></i><span title="Create your first model and get the API endpoint" className="text-truncate tour-title pl-2 font-size-13"> Create your first model and get the API endpoint</span>
                                                </Col>
                                            }

                                            <Col xs={4} className='d-flex align-items-center justify-content-end'>
                                                <a id="ux_start_model_tour" onClick={this.startmodeltour.bind(this)} className="float-right hand font-size-13">Start Tour!</a>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect profilebtn">
                        <img className="rounded-circle header-profile-user me-1" src={user} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
                        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem key={"profile"} onClick={this.select_profile_menu.bind(this, "/profile")}><i className="ri-user-line align-middle me-1"></i> My Account</DropdownItem>
                        <DropdownItem key={"billing"} onClick={this.select_profile_menu.bind(this, "/billing")}><i className="ri-bank-line align-middle me-1"></i> Billing</DropdownItem>
                        <DropdownItem key={"project"} onClick={this.select_profile_menu.bind(this, "/project")}><i className="ri-stack-line align-middle me-1"></i> Projects</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem key={"logout"} onClick={this.select_profile_menu.bind(this, "/logout")} className="text-danger"><i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};
export default connect(mapStatetoProps, { settourpercent, settourstate })(withRouter(ProfileMenu));
