import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER } from './actionTypes';
import { registerUserSuccessful, registerUserFailed, registerUserEmailFailed } from './actions';

//AUTH related methods
import {postSaga, parseEmail} from '../../../helpers/fackBackend_Helper';
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import {axiosApi} from "../../../helpers/api_helper";
import {loginUserSuccessful} from "../login/actions";

// initialize firebase Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
    try {
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.registerUser, user.email, user.password);
            yield put(registerUserSuccessful(response));
        }
        else{
            user.is_operator = false;
            user.firstname = parseEmail(user.email);
            const response = yield call(postSaga, '/auth/register/', user);
            axiosApi.defaults.headers['Authorization'] = "JWT " + response.access;
            localStorage.setItem("access_token", response.access);
            localStorage.setItem("refresh_token", response.refresh);
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("is_operator", 'false');
            localStorage.removeItem("current_project");
            localStorage.setItem("is_profile_completed", response.user.is_profile_completed);
            localStorage.setItem("is_email_verified", response.user.is_email_verified);
            // yield put(registerUserSuccessful(response));
            setTimeout(function() {
                history.push('/email-verify');
            }, 1000);

        }
    } catch (error) {
        if (error === 128) {
            yield put(registerUserEmailFailed(error));
        } else {
            yield put(registerUserFailed(error));
        }
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;