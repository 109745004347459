import { RECOVER_PWD, RECOVER_PWD_SUCCESSFUL, RECOVER_PWD_API_FAILED } from './actionTypes';

const initialState = {
    recoverError: null, message: null, loading: false
}

const Recover = (state = initialState, action) => {
    switch (action.type) {
        case RECOVER_PWD:
            state = {
                ...state,
                user: null,
                loading: true,
                recoverError: null
            }
            break;
        case RECOVER_PWD_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                message: action.payload
            }
            break;
        case RECOVER_PWD_API_FAILED:
            state = {
                ...state,
                loading: false,
                recoverError: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Recover;