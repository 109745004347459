import React from "react";
import { Redirect } from "react-router-dom";

import Logout from "../pages/Authentication/Logout";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import ADLogin from "../pages/Authentication/admin/Login";
import ADTestimonial from "../pages/Testimonial";
import ADCreateTestimonial from "../pages/Testimonial/create";
import ADUpdateTestimonial from "../pages/Testimonial/update";
import ADUpdateFace from "../pages/Face/update";
import ADFeature from "../pages/features";
import ADCreateFeature from "../pages/features/create";
import ADUpdateFeature from "../pages/features/update";
import ADUser from "../pages/User";
import ADCreateUser from "../pages/User/create";
import ADUserProfile from "../pages/User/profile";
import ADOperator from "../pages/Operator";
import ADCreateOperator from "../pages/Operator/create";
import ADOperatorProfile from "../pages/Operator/profile";
import ADProfile from "../pages/Profile/profile";

const adminProtectedRoutes = [
	{ path: "/testimonial", component: ADTestimonial },
	{ path: "/testimonial-create", component: ADCreateTestimonial },
	{ path: "/testimonial-edit/:id", component: ADUpdateTestimonial },
	{ path: "/face", component: ADUpdateFace },
	{ path: "/features", component: ADFeature },
	{ path: "/features-create", component: ADCreateFeature },
	{ path: "/features-edit/:id", component: ADUpdateFeature },
	{ path: "/user", component: ADUser },
	{ path: "/user-create", component: ADCreateUser },
	{ path: "/user-edit/:id", component: ADUserProfile },
	{ path: "/admin", component: ADOperator },
	{ path: "/admin-create", component: ADCreateOperator },
	{ path: "/admin-edit/:id", component: ADOperatorProfile },
	{ path: "/profile", component: ADProfile },

	{ path: "/logout", component: Logout },
];

const landingRoutes = [
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const nfRoutes = [
	{ component: Error404},
];

const publicRoutes = [
	{ path: "/login", component: ADLogin },
	{ path: "/500", component: Error500 },
	{ path: "/404", component: Error404 },

];

export { nfRoutes, landingRoutes, publicRoutes, adminProtectedRoutes };
