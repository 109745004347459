import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import { apiError, loginUserSuccessful, logoutUserSuccess } from './actions';

// AUTH related methods
import { postSaga } from '../../../helpers/fackBackend_Helper';
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { axiosApi } from '../../../helpers/api_helper'
//Initilize firebase
const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
        try {
            if(process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                const response = yield call(fireBaseBackend.loginUser, user.username, user.password);
                yield put(loginUserSuccessful(response));
            }
            else {
                user.is_operator = false;
                const response = yield call(postSaga, '/auth/login/', user);
                axiosApi.defaults.headers['Authorization'] = "JWT " + response.access;
                localStorage.setItem("access_token", response.access);
                localStorage.setItem("refresh_token", response.refresh);                
                localStorage.setItem("authUser", JSON.stringify(response.user));
                localStorage.setItem("is_operator", 'false');
                localStorage.removeItem("current_project");
                localStorage.setItem("is_profile_completed", response.user.is_profile_completed);
                localStorage.setItem("is_email_verified", response.user.is_email_verified);
                yield put(loginUserSuccessful(response));
                if (response.user.is_email_verified === false) {
                    setTimeout(function() {
                        history.push('/email-verify');
                    }, 1000);
                    return;
                }
                /*if (response.user.is_profile_completed === false) {
                    setTimeout(function() {
                        history.push('/profile-update');
                    }, 1000);
                    return;
                }*/
                setTimeout(function() {
                        history.push('/dashboard');
                    }, 1000);

            }

        } catch (error) {            
            yield put(apiError(error));
        }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("is_operator");
        localStorage.removeItem("is_profile_completed");
        localStorage.removeItem("is_email_verified");
        axiosApi.defaults.headers['Authorization'] = null;

        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(response));
        }

        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default loginSaga;