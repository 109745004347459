import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../store/actions";
import {
    private_get,
    getCurrentProject,
    alert3,
    putTourState,
    getTourState,
    isTourOpened, confirmNextStep, private_post
} from '../../helpers/fackBackend_Helper';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Button,
    PopoverHeader,
    PopoverBody,
    Popover,
    UncontrolledPopover
} from "reactstrap";
import Hotspot from "../Common/hotspot";
import Tour from "../Common/Tour";
import {MY_PROJECT_URL} from "../../helpers/url_helper";


class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project_selected: this.get_current_project(),
            project_list_open: false,
        };
        this.projects = [];
        this.projectactions = [];
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleProject_list = this.toggleProject_list.bind(this);
        this.select_project = this.select_project.bind(this);
        this.handlemenu = this.handlemenu.bind(this);
    }

    handlemenu(e, href) {
        e.preventDefault();
        if (href === "/profile"){
            if (getTourState(2)) {
                confirmNextStep(2);
            }
            if (getTourState(3)) {
                confirmNextStep(3);
            }
            this.props.history.push(href);

        }else if (href === "/model"){
            confirmNextStep(4);
            if (getCurrentProject() === null) {
                alert3('Project does not exist, please select a project or create new one to continue', 'warning');return;
            }
            this.props.history.push(href);

        }
    }

    toggleMenu() {
        // this.props.sidebartoggleMenuCallback();
    }
    toggleProject_list() {
        this.setState({ project_list_open: !this.state.project_list_open });
    }
    select_project(o) {

        if (o.id === "create_new_project") {
            this.props.history.push("/project-create");
        }
        else if (o.id === "manage_project") {
            this.props.history.push("/project");
        } else {
            const cur_project = localStorage.getItem('current_project');
            if (cur_project !== null) {
                const project = JSON.parse(cur_project);
                if (project.id === o.id) {
                    return;
                }
            }
            // var url = '/auth/switch_project/' + o.id + "/";
            // private_get(url);
            this.setState({project_selected: o.name});
            localStorage.setItem("current_project", JSON.stringify(o));
            if (this.props.location.pathname === '/model') {
                this.props.history.go(0);
            } else {
                this.props.history.push('/model');
            }
        }
    }
    async init_project_list() {
        const url = MY_PROJECT_URL;
        try {
            this.projects = await private_get(url);

            const cur_project = localStorage.getItem('current_project');
            if (cur_project === null) {
                if (this.projects.length > 0 ) {
                    // var surl = '/auth/switch_project/' + this.projects[0].id + "/";
                    // private_get(surl);
                    localStorage.setItem("current_project", JSON.stringify(this.projects[0]));
                    this.setState({project_selected: this.projects[0].name});
                } else{
                    this.setState({project_selected: 'Projects'});
                }
            }
            this.projectactions.push({"id": "create_new_project", "name": "New Project"});
            this.projectactions.push({"id": "manage_project", "name": "Manage Projects"});
        }
         catch (err) {
        }

    }
    get_current_project() {
        const cur_project = localStorage.getItem('current_project');
        var project = '';
        if (cur_project !== null) {
            project = JSON.parse(cur_project);
            return project.name;
        }
    }
    componentDidMount() {
        this.initMenu();
        this.init_project_list();
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {

            if (this.props.type !== prevProps.type) {
                this.initMenu();
                this.init_project_list();
            }

        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li>
                            <Link id="ux_dashboard_li" to="/dashboard" className="waves-effect">
                                <i className="ri-line-chart-fill"></i>
                                <span className="ms-1">{this.props.t('Dashboard')}</span>
                            </Link>
                        </li>
                        <li className="menu-title li-menu-title li-classifier_line">
                            <div className="classifier_line"></div>
                        </li>
                        <li className="menu-title li-menu-title li_classifier_project">
                                <span className="ms-1 ">Select a project</span>
                        </li>
                        <li className="select3-li" id="sidebar_project_li">
                            <Link to="/project" className="d-flex align-items-center" onClick={ (event) => event.preventDefault() }>
                                <i className="ri-stack-fill"></i>
                                <div className="d-inline-block select3-dv">
                                <Dropdown className="dropdown-2" isOpen={this.state.project_list_open} toggle={this.toggleProject_list}>
                                      <DropdownToggle caret>
                                        <span className="selectedSpan">{this.state.project_selected}</span>
                                        <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-2">
                                          {this.projects.map(o => <DropdownItem key={o.id} onClick={this.select_project.bind(this, o)}>{o.name}</DropdownItem>)}
                                          {this.projects.length > 0 &&
                                              <DropdownItem divider />
                                          }
                                          {this.projectactions.map(o => <DropdownItem id={o.id} key={o.id} onClick={this.select_project.bind(this, o)}>{o.name}
                                          </DropdownItem>)}
                                      </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Link>
                        </li>
                        <li id="ux_model_li">
                            <Link to="/model" className=" waves-effect" onClick={ (e) => this.handlemenu(e, "/model") }>
                                <i className="ri-vip-diamond-fill c-diamond-sz"></i>
                                <span className="ms-1">{this.props.t('Models')}</span>
                            </Link>
                        </li>
                    </ul>
                    <ul className="metismenu bottom-menu list-unstyled" id="side-menu">
                        <li id="ux_setting_li">
                            <Link to="/profile" className="waves-effect" onClick={ (e) => this.handlemenu(e, "/profile") }>
                                <i className=" ri-settings-5-line"></i>
                                <span className="ms-1">{this.props.t('Setting')}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
})(withNamespaces()(SidebarContent)));

