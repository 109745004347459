import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { RECOVER_PWD } from './actionTypes';
import { recoverpwdSuccessful, recoverpwdError } from './actions';

// AUTH related methods
import { postSaga } from '../../../helpers/fackBackend_Helper';

//If user is login then dispatch redux action's are directly from here.
function* recoverpwd({ payload: { user, history } }) {
    try {
        const response = yield call(postSaga, '/auth/forgot_password/reset/', user);
        if (response) {
            yield put(
                recoverpwdSuccessful(
                "Your password was reset successfully."
              )
            );
            setTimeout(function(){
                history.push('/login');
            }, 2000);

        }
    } catch (error) {
        yield put(recoverpwdError(error));
    }
}

export function* watchUserRecover() {
    yield takeEvery(RECOVER_PWD, recoverpwd)
}

function* recoverSaga() {
    yield all([fork(watchUserRecover)]);
}

export default recoverSaga;