import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../store/actions";
import {getLoggedInUser} from "../../helpers/fackBackend_Helper";


class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project_selected: "Project",
            project_list_open: false,
            my_group: {
                admin: false,
                user: false
            }
        };
        this.projects = [];
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    toggleMenu() {
        // this.props.sidebartoggleMenuCallback();
    }
    componentDidMount() {
        this.initMenu();
        const profile = getLoggedInUser();
        var groupview = {};
        if (profile.groups === 1) {
            groupview.admin = true;
            groupview.user = true;
        }
        if (profile.groups === 2) {
            groupview.admin = false;
            groupview.user = true;
        }
        if (profile.groups === 3) {
            groupview.admin = false;
            groupview.user = true;
        }
        this.setState({my_group: groupview});
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {

            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }

        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title li-menu-title li_classifier_project">
                                <span className="ms-1 ">Accounts</span>
                        </li>
                        <li>
                            <Link to="/profile" className=" waves-effect">
                                <i className="ri-user-2-fill"></i>
                                <span className="ms-1">{this.props.t('My Account')}</span>
                            </Link>
                        </li>

                        {this.state.my_group.admin &&
                            <li>
                                <Link to="/admin/" className=" waves-effect">
                                    <i className="ri-admin-fill"></i>
                                    <span className="ms-1">{this.props.t('Admins')}</span>
                                </Link>
                            </li>
                        }
                        {this.state.my_group.user &&
                            <li>
                                <Link to="/user" className=" waves-effect">
                                    <i className="ri-team-fill"></i>
                                    <span className="ms-1">{this.props.t('Marketers')}</span>
                                </Link>
                            </li>
                        }
                        <li className="menu-title li-menu-title li-classifier_line">
                            <div className="classifier_line"></div>
                        </li>
                        <li className="menu-title li-menu-title li_classifier_project">
                                <span className="ms-1 ">Testimonial</span>
                        </li>
                        <li>
                            <Link to="/testimonial" className=" waves-effect">
                                <i className=" ri-honour-fill"></i>
                                <span className="ms-1">{this.props.t('On Landing')}</span>
                            </Link>
                        </li>
                        <li className="menu-title li-menu-title li-classifier_line">
                            <div className="classifier_line"></div>
                        </li>
                        <li className="menu-title li-menu-title li_classifier_project">
                                <span className="ms-1 ">CMS</span>
                        </li>
                        <li>
                            <Link to="/face" className=" waves-effect">
                                <i className="ri-home-smile-2-fill"></i>
                                <span className="ms-1">{this.props.t('Face Content')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/features" className=" waves-effect">
                                <i className=" ri-profile-fill"></i>
                                <span className="ms-1">{this.props.t('Features Content')}</span>
                            </Link>
                        </li>


                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
})(withNamespaces()(SidebarContent)));

