import axios from "axios";
import {MY_MODEL_URL} from "./url_helper";
import {getCurrentProject} from "./fackBackend_Helper";

//pass new generated access token here
// const token = accessToken;

//apply base url for axios
// const API_URL = ""
const API_URL = process.env.REACT_APP_API_URL;

export {API_URL};
const axiosApi = axios.create({
  baseURL: API_URL,
  'Content-Type': 'application/json',
  'accept': 'application/json'
});
if (process.env.REACT_APP_AXIOS_HAS_CREDENTIALS === 'true') {
    axiosApi.defaults.withCredentials = true;
}

axiosApi.defaults.headers["Authorization"] = localStorage.getItem('access_token') ? "JWT " + localStorage.getItem('access_token') : null;
axiosApi.interceptors.response.use(
  response => response,
    error => {
        const originalRequest = error.config;

        // Prevent infinite loops
        if (error.response.status === 401 && originalRequest.url === API_URL+'/auth/refresh/') {
            window.location.href = '/login/';
            return Promise.reject(error);
        }
        if (error.response.data.code === "token_not_valid" &&
            error.response.status === 401 && 
            error.response.statusText === "Unauthorized") 
            {
                const refreshToken = localStorage.getItem('refresh_token');

                if (refreshToken){
                    const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

                    // exp date in token is expressed in seconds, while now() returns milliseconds:
                    const now = Math.ceil(Date.now() / 1000);

                    if (tokenParts.exp > now) {
                        return axiosApi
                        .post('/auth/refresh/', {refresh: refreshToken})
                        .then((response) => {
            
                            localStorage.setItem('access_token', response.data.access);                            
            
                            axiosApi.defaults.headers['Authorization'] = "JWT " + response.data.access;
                            originalRequest.headers['Authorization'] = "JWT " + response.data.access;
            
                            return axiosApi(originalRequest);
                        })
                        .catch(err => {
                            window.location.href = '/login/';
                        });
                    }else{
                        window.location.href = '/login/';
                    }
                }else{
                    window.location.href = '/login/';
                }
        }
      
     
      // specific error handling done elsewhere
      // throw error
      return Promise.reject(error);
  }
)
export { axiosApi }

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response)
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response)
}

export async function post_multipart(url, data, config = {}) {
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response)
}
export async function put_multipart(url, data, config = {}) {
  return await axiosApi
    .put(url, data, { ...config })
    .then(response => response)
}

export async function put(url, data, config = {}) {
  return await axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response)
}
export async function patch(url, data, config = {}) {
  return await axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response)
}
