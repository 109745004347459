import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_SIDEBAR_THEME,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	TOGGLE_RIGHT_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	CHANGE_PRELOADER,
	HIDE_RIGHT_SIDEBAR,
	SET_TOUR_OBJ,
	SET_PROJECT_TOUR_OBJ,
	SET_PAYMENT_TOUR_OBJ,
	SET_MODEL_TOUR_OBJ, SET_TOUR_PERCENT,
	SET_DOCMENT,
	SET_TOUR_STATE,
	SET_TO_MODEL_TOUR_OBJ,
	SET_TO_PROJECT_TOUR_OBJ
} from "./actionTypes";

export const setdocument = (obj) => {
	return {
		type: SET_DOCMENT,
		payload: obj
	}
};
export const settourstate = (obj) => {
	return {
		type: SET_TOUR_STATE,
		payload: obj
	}
};
export const settourpercent = (obj) => {
	return {
		type: SET_TOUR_PERCENT,
		payload: obj
	}
};

export const settourobj = (obj) => {
	return {
		type: SET_TOUR_OBJ,
		payload: obj
	}
};
export const setpaymenttourobj = (obj) => {
	return {
		type: SET_PAYMENT_TOUR_OBJ,
		payload: obj
	}
};
export const setprojecttourobj = (obj) => {
	return {
		type: SET_PROJECT_TOUR_OBJ,
		payload: obj
	}
};
export const setmodeltourobj = (obj) => {
	return {
		type: SET_MODEL_TOUR_OBJ,
		payload: obj
	}
};

export const settourtoprojecttourobj = (obj) => {
	return {
		type: SET_TO_PROJECT_TOUR_OBJ,
		payload: obj
	}
};
export const settourtomodeltourobj = (obj) => {
	return {
		type: SET_TO_MODEL_TOUR_OBJ,
		payload: obj
	}
};


export const changeLayout = layout => ({
	type: CHANGE_LAYOUT,
	payload: layout
});

export const changePreloader = layout => ({
	type: CHANGE_PRELOADER,
	payload: layout
});

export const changeLayoutWidth = (width, layoutType) => ({
	type: CHANGE_LAYOUT_WIDTH,
	payload:  { width, layoutType }
});

export const changeSidebarTheme = (theme, layoutType) => ({
	type: CHANGE_SIDEBAR_THEME,
	payload: {theme, layoutType}
});

export const changeSidebarType = (sidebarType, isMobile) => {
	return {
		type: CHANGE_SIDEBAR_TYPE,
		payload: { sidebarType, isMobile }
	};
};

export const changeTopbarTheme = topbarTheme => ({
	type: CHANGE_TOPBAR_THEME,
	payload: topbarTheme
});


export const toggleRightSidebar = () => ({
	type: TOGGLE_RIGHT_SIDEBAR,
	payload: null
});

export const showRightSidebar = () => ({
	type: SHOW_RIGHT_SIDEBAR,
	payload: null
});

export const hideRightSidebar = () => ({
	type: HIDE_RIGHT_SIDEBAR,
	payload: null
});
