import React, { Component, useContext } from "react";
import {Row, Col, Alert, Card, CardBody, Button, Label, Container, InputGroup} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {
    private_post,
    private_get,
    alert3,
    open_loading,
    close_loading,
    private_put, private_put_multipart
} from '../../helpers/fackBackend_Helper';
import {FEATURE_URL, TESTIMONIAL_URL} from "../../helpers/url_helper";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";

class ADUpdateFeature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: [],
            title: "",
            subtitle: "",
            content: "",
            image_url: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleAcceptedFiles = files => {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size)
          })
        );
        this.setState({ selectedFiles: files });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
    async componentDidMount() {
        const _id = this.props.match.params.id;
        const url = FEATURE_URL + _id + "/";
        var resp = await private_get(url);
        if (resp) {
            this.setState({
                title: resp.title,
                content: resp.content,
                subtitle: resp.subtitle,
                image_url: resp.image_url,
            });
        }
    }

    handleCancel() {
        this.props.history.push("/features");
    }
    async handleSubmit(event, values) {
        try{
            let form_data = new FormData();
            form_data.append("title", values.title);
            form_data.append("subtitle", values.subtitle);
            form_data.append("content", values.content);
            if (this.state.selectedFiles.length > 0) {
                form_data.append("image", this.state.selectedFiles[0], this.state.selectedFiles[0].name);
            }
            open_loading();
            const content_type = {"Content-Type": "multipart/form-data"};
            const config = {
                headers: {...content_type},
            };
            const url = FEATURE_URL +  + this.props.match.params.id + "/";
            const resp = await private_put_multipart(url, form_data, config);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push("/features");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col>
                            <h4 className="card-title-x">Edit Feature Content</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row className="mb-4">
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="content">Image</Label>
                                                <Dropzone onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                  <div className="dropzone">
                                                    <div
                                                      className="dz-message needsclick"
                                                      {...getRootProps()}
                                                    >
                                                      <input {...getInputProps()} />
                                                      <div className="mb-3">
                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                      </div>
                                                      <h4>Drop an image here or click to upload.</h4>
                                                    </div>
                                                  </div>
                                                )}
                                              </Dropzone>

                                            </Col>
                                            {this.state.selectedFiles.length > 0 &&
                                            <Col lg="6">
                                                <div className="dropzone-previews" id="file-previews">
                                                {this.state.selectedFiles.map((f, i) => {
                                                  return (
                                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                                                      <div className="p-2">
                                                        <Row className="align-items-center">
                                                          <Col>
                                                            <img
                                                              data-dz-thumbnail=""
                                                              className="rounded bg-light w-100"
                                                              alt={f.name}
                                                              src={f.preview}
                                                            />
                                                          </Col>
                                                        </Row>
                                                          <Row>
                                                          <Col>
                                                            <Link
                                                              to="#"
                                                              className="text-muted fw-bold"
                                                            >
                                                              {f.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                              <strong>{f.formattedSize}</strong>
                                                            </p>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </Card>
                                                  );
                                                })}
                                              </div>
                                            </Col>
                                            }
                                            {this.state.selectedFiles.length === 0 &&
                                                <Col lg="6">
                                                    <div>
                                                        <img className="w-100" src={this.state.image_url} />
                                                    </div>
                                                </Col>
                                            }

                                        </Row>

                                        <Row className="mb-2">
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="">(Note: To render blue text in a title, sub title and content, add two asterisks before and after a word or phrase)</Label>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col lg="6">
                                            <div>
                                                <Label className="form-label" htmlFor="name">Title</Label>
                                                <AvField
                                                name="title"
                                                type="text"
                                                errorMessage="Enter the title"
                                                className="form-control"
                                                placeholder="**Trailblazer** In Lending Document Processing"
                                                validate={{ required: { value: true } }}
                                                value={this.state.title}
                                                />
                                            </div>
                                            </Col>

                                            <Col lg="6">
                                            <div>
                                                <Label className="form-label" htmlFor="name">Sub Title </Label>
                                                <AvField
                                                name="subtitle"
                                                type="text"
                                                errorMessage="Enter the sub title"
                                                className="form-control"
                                                value={this.state.subtitle}
                                                />
                                            </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="content">Content</Label>
                                                <AvField
                                                name="content"
                                                type="textarea"
                                                errorMessage="Enter the feature content"
                                                className="form-control content_txa"
                                                validate={{ required: { value: true } }}
                                                value={this.state.content}
                                                />
                                            </div>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADUpdateFeature