import React, { Component } from "react";
import {Spinner} from "reactstrap";

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="tbindex" id="loading">
                    <div className="">
                        <div className="modal fade show d-block" role="dialog">
                            <Spinner className="loadingspin" />
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            </React.Fragment>
        );
    }
}

export default Loading;
