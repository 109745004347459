import { RECOVER_PWD, RECOVER_PWD_SUCCESSFUL, RECOVER_PWD_API_FAILED } from './actionTypes';

export const recoverpwd = (user, history) => {

    return {
        type: RECOVER_PWD,
        payload: { user, history }
    }
}

export const recoverpwdSuccessful = (message) => {
    return {
        type: RECOVER_PWD_SUCCESSFUL,
        payload: message
    }
}

export const recoverpwdError = (error) => {
    return {
        type: RECOVER_PWD_API_FAILED,
        payload: error
    }
}