import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logoutUser } from '../../store/actions';
import {API_URL} from "../../helpers/api_helper";
import {private_post, public_post} from "../../helpers/fackBackend_Helper";

class Logout extends Component {

    constructor(props) {
        super(props);
        this.state = {  };
    }

    async componentDidMount() {
        const url = "/auth/logout";
        const data = {
            refresh_token: localStorage.getItem("refresh_token")
        };
        try {
            const resp = await private_post(url, data);
        }
        catch(err) {
        }
        // Fire Action for Remove all Item from localstorage and redirect to login page
        this.props.logoutUser(this.props.history);
    }

    render() {
        return (
            <React.Fragment>
               <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null,{ logoutUser })(Logout));

