import React, { Component } from "react";
import riffle from "../../assets/images/hotspot_pulse.gif";

class Hotspot extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <React.Fragment>
                <img className="hotspot_img" src={riffle} />
            </React.Fragment>
        );
    }
}

export default Hotspot;
