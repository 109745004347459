import React, { Component } from "react";
import {Row, Col, Alert, Card, CardBody, Button, Label, Container, InputGroup} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    getLoggedInUser,
    private_post,
    private_put,
    back_to_dashboard,
    alert3,
    open_loading,
    close_loading, private_get, getformatdate, futuredate
} from '../../helpers/fackBackend_Helper';
import {
    MEMBERSHIP_TYPE_URL,
    OPERATOR_URL,
    PAYMENT_TERM_TYPE_URL,
    PAYMENT_TERM_URL,
    USER_URL,
    MEMBERSHIP_URL
} from "../../helpers/url_helper";
import {countries} from "../../common/data/country";

class ADUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_terms: [],
            payment_term: "",
            memberships: [],
            membership: "",
            email: "",
            username: "",
            firstname: "",
            lastname: "",
            address: "",
            company: "",
            country: "US",
            phone: "",
            password: "",
            confirm_password: "",
            group: "2"
        };
    }
    handleCancel() {
        this.props.history.push('/user');
    }
    async handleresetpassword(event, values) {
        if (values.password !== values.confirm_password) {
            alert3("Password doesn't match", "warning");return;
        }
        delete values.confirm_password;

        try{
            open_loading();
            const url = USER_URL + this.props.match.params.id + "/";

            const resp = await private_put(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push('/user');
            }
        }
        catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    handleChange(e) {
        this.setState({ country: e.target.value });
    }
    handleChangeGroup(e) {
        this.setState({ group: e.target.value });
    }

    async componentDidMount() {
        const url = USER_URL + this.props.match.params.id + "/";
        const resp = await private_get(url);
        this.setState({
            firstname:resp.firstname,
            lastname:resp.lastname,
            email:resp.email,
            address:resp.address,
            company:resp.company,
            phone:resp.phone,
            group: resp.groups
        });
        if (resp.country !== ''){
            this.setState({
                country: resp.country
            });
        }
    }

    async handleSubmit(event, values) {
        try{
            values.country = this.state.country;
            values.username = values.email;
            values.groups = this.state.group;

            const url = USER_URL + this.props.match.params.id + "/";
            open_loading();
            const resp = await private_put(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push('/user');
            }
        }
        catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                  <Row>
                      <Col xl={6}>
                            <h4 className="card-title-x">Profile</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit.bind(this)} className="needs-validation" >
                                    <Row>
                                        <Col xl={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="firstname">First Name *</Label>
                                            <AvField
                                            name="firstname"
                                            type="text"
                                            errorMessage="Enter First Name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="firstname"
                                            value={this.state.firstname}
                                            />
                                        </div>
                                        </Col>
                                        <Col xl={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="lastname">Last Name *</Label>
                                            <AvField
                                            name="lastname"
                                            type="text"
                                            errorMessage="Enter Last name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="lastname"
                                            value={this.state.lastname}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="email">Email *</Label>
                                            <AvField
                                            name="email"
                                            type="email"
                                            errorMessage="Enter your email"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="email"
                                            value={this.state.email}
                                            disabled
                                            />
                                        </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="country">Country *</Label>
                                            <select className="form-control" name='country' id='country' value={this.state.country} onChange={this.handleChange.bind(this)}>
                                              {countries.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                            </select>
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="role">Role *</Label>
                                            <select className="form-control" name="role" id="role" onChange={this.handleChangeGroup.bind(this)} value={this.state.group}>
                                                <option value="2">Marketing Manager</option>
                                                <option value="3">Marketer</option>
                                            </select>
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="address">Address</Label>
                                            <AvField
                                            name="address"
                                            type="text"
                                            errorMessage="Enter your address"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="address"
                                            value={this.state.address}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="company">Company</Label>
                                            <AvField
                                            name="compnay"
                                            type="text"
                                            errorMessage="Enter your compnay"
                                            className="form-control"
                                            id="company"
                                            value={this.state.company}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="phone">Phone No.</Label>
                                            <AvField
                                            name="phone"
                                            type="tel"
                                            errorMessage="Enter your phone"
                                            className="form-control"
                                            id="phone"
                                            value={this.state.phone}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                        <Row>
                                            <Col>
                                                <div className="button-items">
                                    <Button color="primary" type="submit">Save</Button>
                                        <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div></Col></Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                  </Row>
                    <Row>


                        <Col xl={6}>
                            <h4 className="card-title-x">Reset password</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleresetpassword.bind(this)} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">New Password</Label>
                                                <AvField
                                                name="password"
                                                type="password"
                                                errorMessage="Enter password"
                                                className="form-control"
                                                validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }}
                                                value={this.state.password}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm_password">Confirm Password</Label>
                                                <AvField
                                                name="confirm_password"
                                                type="password"
                                                errorMessage="Enter password"
                                                className="form-control"
                                                validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }}
                                                value={this.state.confirm_password}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="button-items">
                                                        <Button color="primary" type="submit">Save</Button>
                                                        <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div></Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>



                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADUserProfile