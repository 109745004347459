import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import user from '../../assets/images/users/user.png';
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    select_profile_menu(url) {
        this.props.history.push(url);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {

        let username = "";
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            // const uNm = obj.email.split("@")[0];
            username = obj.firstname + " " + obj.lastname;
        }

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        <img className="rounded-circle header-profile-user me-1" src={user} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
                        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem key={"profile"} onClick={this.select_profile_menu.bind(this, "/profile")} ><i className="ri-user-line align-middle me-1"></i> Profile</DropdownItem>
                        <DropdownItem key={"logout"} onClick={this.select_profile_menu.bind(this, "/logout")} className="text-danger"><i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};
export default connect(mapStatetoProps, {  })(withRouter(ProfileMenu));
