import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Container,
    Card,
    Input,
    CardBody
} from "reactstrap";
import {
    private_get,
    private_del,
    private_put,
    alert3,
    open_loading, close_loading, getLoggedInUser
} from '../../helpers/fackBackend_Helper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../Tables/datatables.scss";
import {USER_URL} from "../../helpers/url_helper";

class ADUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Project", link: "#" },
        { title: "List", link: "#" },
      ],
      sizePerPage: 10,
      userData: [],
      row_id: null

    };
    this.linkFollow = this.linkFollow.bind(this);
    this.statusformatter = this.statusformatter.bind(this);
    this.userstatusupdate = this.userstatusupdate.bind(this);
    this.deleteuser = this.deleteuser.bind(this);
    this.edituser = this.edituser.bind(this);
  }

  statusformatter(cell, row, rowIndex, formatExtraData) {
      return (
          <div className="form-check form-switch" dir="ltr">
            <Input onClick={() => {this.userstatusupdate(cell, row, rowIndex)}} type="checkbox" className="form-check-input cswitch" defaultChecked={row.is_active}/>
        </div>
      )
  }

  cancel_del_user() {
      document.getElementById('user_del_confirm_modal').style.display = 'none';
  }
  async confirm_del_user() {
      document.getElementById('user_del_confirm_modal').style.display = 'none';
      const url = USER_URL + this.state.row_id + "/";
      try{
          open_loading();
          const resp = await private_del(url);
          if (resp || resp === '') {
              alert3("Success", 'success');
              this.loadTable();
          }
      } catch (err) {
          alert3(err, 'error');
      }
      close_loading();
  }
  handlecreateuser() {
    this.props.history.push("/user-create");
  }
  deleteuser(cell, row, row_index) {
      this.setState({row_id: row.id});
      document.getElementById('user_del_confirm_modal').style.display = 'block';
  }
  edituser(cell, row, row_index) {
      const url = "/user-edit/" + row.id;
      this.props.history.push(url);
  }
  async userstatusupdate(cell, row, row_index) {
      const url = USER_URL + row.id + "/";
      var data = {is_active: false};
      if (row.is_active === 'Disabled') {
          data = {is_active: true};
      }
      data.email = row.email;
      try{
          open_loading();
          const resp = await private_put(url, data);
          if (resp) {
              alert3("Success", 'success');
              this.loadTable();
          }
      } catch (err) {
          alert3(err, 'error');
      }
      close_loading();
  }

  async loadTable() {
    const url = USER_URL;
    const resp = await private_get(url);
    const data = [];
    const profile = getLoggedInUser();
    for (var i=0; i < resp.length; i++) {
        if (profile.id !== resp[i].id) {
            var param = {};
            param = {...resp[i]};
            param.id = resp[i].id;
            param.is_active = "Disabled";
            if (resp[i].is_active) {
                param.is_active = "Enabled";
            }
            param.name = resp[i].firstname + " " + resp[i].lastname;
            param.role = "Marketing Manager";
            if (param.groups === 3) {
                param.role = "Marketer";
            }
            data.push(param);
        }
    }
    this.setState({userData: data});
  }

  componentDidMount() {
    this.loadTable();
  }
  linkFollow (cell, row, rowIndex, formatExtraData) {
    return (
      <div>
          <a title="Update the marketer's details" onClick={() => {this.edituser(cell, row, rowIndex)}} className="me-1 actionbtn purple"><i className="ri-pencil-fill"></i></a>
        <a title="Delete the marketer" onClick={() => {this.deleteuser(cell, row, rowIndex)}} className="actionbtn red"><i className="ri-delete-bin-fill"></i></a>
      </div>
    )
  }
  render() {

    const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true
    }, {
      dataField: 'role',
      text: 'Role',
      sort: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'login_ip',
      text: 'Login IP',
      sort: true
    }, {
      dataField: 'last_login',
      text: 'Last login',
      sort: true
    }, {
      dataField: 'status',
      text: 'Status',
      style: {width: "10%"},
      headerStyle: {width: "10%"},
      formatter: this.statusformatter
    }, {
      dataField: 'action',
      text: 'Action',
      style: {width: "10%"},
      headerStyle: {width: "10%"},
      formatter: this.linkFollow
    },

    ];

    const defaultSorted = [{
      dataField: 'name',
      order: 'asc'
    }];
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } Results
      </span>
    );
    const pageOptions = {
        alwaysShowAllBtns: true,
        prePageText: '<',
        nextPageText: '>',
        withFirstAndLast : true,
        firstPageText: '<<',
        lastPageText: '>>',
        showTotal: true,
        disablePageTitle: true,
        sizePerPage: 20,
        paginationTotalRenderer: customTotal,
        totalSize: this.state.userData.length,
        sizePerPageList: [{
            text: '5', value: 5
          }, {
            text: '10', value: 10
          }, {
            text: '20', value: 20
          }, {
            text: 'All', value: this.state.userData.length
          }]
    };
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
              <Row>
                <Col lg={12}>
                      <Card>
                        <CardBody>
                            <Row>
                              <Col lg={12}>
                                  <div className="custom-table">
                                      <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField='id'
                                  columns={columns}
                                  data={this.state.userData}
                                >
                                  {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField='id'
                                      columns={columns}
                                      data={this.state.userData}
                                      search
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>

                                          <Row>
                                            <Col>
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="ri-search-line appsearch" />
                                                </div>
                                              </div>
                                                <Button onClick={this.handlecreateuser.bind(this)} className="float-right" color="primary" type="submit">New Marketer</Button>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col>
                                              <div className="custom-table">
                                                <BootstrapTable
                                                  noDataIndication="Table is Empty"
                                                  keyField={"id"}
                                                  responsive
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={defaultSorted}
                                                  classes={
                                                    "table align-middle table-nowrap"
                                                  }
                                                  headerWrapperClasses={"thead-light"}
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />

                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )
                                      }
                                    </ToolkitProvider>
                                  )
                                  }</PaginationProvider>
                                  </div>
                              </Col>
                            </Row>
                        </CardBody></Card>
                </Col>
              </Row>
              <div className="tbindex" id="user_del_confirm_modal">
                    <div className="">
                        <div className="modal fade show d-block" role="dialog">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="warning2rect">
                                                <span className="wn2ic1"></span>
                                                <span className="wn2ic2"></span>
                                            </div>
                                            <span className="font-size-16">Are you sure?</span>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={this.confirm_del_user.bind(this)} type="button" className="btn btn-primary">OK</button>
                                        <button onClick={this.cancel_del_user.bind(this)} type="button" className="btn btn-secondary">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ADUser