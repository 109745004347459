import React from "react";
import { Route, Redirect } from "react-router-dom";
import { axiosApi } from '../../src/helpers/api_helper';

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	isAdpAdmin,
	isPreprocessing,
	path,
	...rest
}) => (
		<Route
			{...rest}
			render={props => {
				if (!isAdpAdmin && isAuthProtected && !localStorage.getItem("access_token")) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}
				if (!isAuthProtected && path !== undefined) {
					if (path === '/login'){
						localStorage.removeItem("authUser");
						localStorage.removeItem("access_token");
						localStorage.removeItem("refresh_token");
						axiosApi.defaults.headers['Authorization'] = null;
					}
				}
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default AppRoute;

