import React, { Component } from "react";

import { connect } from "react-redux";
import {Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/images/logo-light.png";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            document_list_open: false,
            document_list_mb_open: false,
            resource_list_open: false,
            resource_list_mb_open: false
        };
    }

    onclicklogo() {
        this.props.history.push('/');
    }
    bookdemo() {
        if (this.props.history.location.pathname === '/') {
            window.location.href='#feedback';
            document.getElementById("name").focus();
            document.getElementById('bookdemoclicked').value = "";
        } else{
            document.getElementById('bookdemoclicked').value = "true";
            this.props.history.push('/');
        }
    }
    mouseoverdocmenu() {
        this.setState({ document_list_open: true });
    }
    mouseleavedocmenu() {
        this.setState({ document_list_open: false });
    }
    mb_mouseoverdocmenu() {
        this.setState({ document_list_mb_open: true });
    }
    mb_mouseleavedocmenu() {
        this.setState({ document_list_mb_open: false });
    }

    mouseleaveresourcemenu() {
        this.setState({ resource_list_open: false });
    }
    mouseoverresourcemenu() {
        this.setState({ resource_list_open: true });
    }
    mb_mouseleaveresourcemenu() {
        this.setState({ resource_list_mb_open: false });
    }
    mb_mouseoverresourcemenu() {
        this.setState({ resource_list_mb_open: true });
    }
    toggleresource_list() {
        this.setState({ resource_list_open: !this.state.resource_list_open });
    }
    mb_toggleresource_list() {
        this.setState({ resource_list_mb_open: !this.state.resource_list_mb_open });
    }

    gotologin() {
        // this.props.history.push('/login');
        window.location.href = "https://console.ellietrac.ai/login";
    }
    toggledoc_list() {
        this.setState({ document_list_open: !this.state.document_list_open });
    }
    toggledoc_mb_list() {
        this.setState({ document_list_mb_open: !this.state.document_list_mb_open });
    }

    expandmenu() {
        var link = document.getElementById('menud');

        if (link.classList.contains('mobile_active')){
            link.classList.remove('mobile_active');
        } else {
            link.classList.add('mobile_active');
        }
        var ul = document.getElementById('mmc-ul');

        if (ul.classList.contains('mmc-ul-active')){
            ul.classList.remove('mmc-ul-active');
        } else {
            ul.classList.add('mmc-ul-active');
        }

    }
    render() {
        return (
            <React.Fragment>
                <header className="land-header" id="page-topbar">
                    <div className="container" id="menud">

                        <div className="navbar-header">
                            <div className="d-flex l-nav h-100">
                                <img onClick={this.onclicklogo.bind(this)} className="hand" src={logo} />
                            </div>

                            <div className="d-flex r-nav align-items-center">
                                <Link to="/">Home</Link>
                                <Link id="land-product" to="/product">Products</Link>
                                <div className="d-none" id="land-document" to="/document" onClick={ (event) => event.preventDefault() }>
                                    <Dropdown onMouseLeave={this.mouseleavedocmenu.bind(this)} onMouseOver={this.mouseoverdocmenu.bind(this)} className="dropdown-2" isOpen={this.state.document_list_open} toggle={this.toggledoc_list.bind(this)}>
                                      <DropdownToggle caret>
                                        <a className="inner-land-document">Document AI</a>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-2 land-dropdown2">
                                        <Row>
                                            <Col>
                                                <ul className="list-unstyled document-sub-menu">
                                                    <li>
                                                        <Link to="/document/classification">Document Classification</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/document/us-driver-license">US Driver License</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/document/bank-state">Bank Statement</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/document/1003">1003 Form</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/document/1120">1120 Form</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/document/1120s">1120s Form</Link>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                      </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <Link id="land-company" to="/company">Company</Link>
                                <div id="land-resources" >
                                    <Dropdown onMouseLeave={this.mouseleaveresourcemenu.bind(this)} onMouseOver={this.mouseoverresourcemenu.bind(this)} className="dropdown-2" isOpen={this.state.resource_list_open} toggle={this.toggleresource_list.bind(this)}>
                                      <DropdownToggle caret>
                                        <a className="">Resources</a>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-2 land-dropdown2">
                                        <Row>
                                            <Col>
                                                <ul className="list-unstyled document-sub-menu">
                                                    <li>
                                                        <a className="hand" href="https://docs.ellietrac.ai">Documentation</a>
                                                    </li>
                                                    <li>
                                                        <a className="hand" href="https://docs.ellietrac.ai/usecases/">Use Cases</a>
                                                    </li>
                                                    <li>
                                                        <a className="hand" href="https://blog.ellietrac.ai">Blog</a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                      </DropdownMenu>
                                    </Dropdown>
                                </div>

                                <Button onClick={this.gotologin.bind(this)} className="btn l-login-btn"><i className="ri-user-line"></i><span>Login</span></Button>
                                <Button onClick={this.bookdemo.bind(this)} className="btn l-gd-btn"><span>Book Demo</span></Button>
                            </div>

                            <div className="mobile-menu">
                                <i onClick={this.expandmenu.bind(this)} className="ri-menu-line hand"></i>
                            </div>
                        </div>

                        <div className="mobile-menu-content">
                            <div id="mmc-ul">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link id="land-product-mb" to="/product">Products</Link>
                                    </li>
                                    <li className="d-none">
                                        <div className="d-block" id="land-document-mb" to="/document" onClick={ (event) => event.preventDefault() }>
                                            <Dropdown onMouseLeave={this.mb_mouseleavedocmenu.bind(this)} onMouseOver={this.mb_mouseoverdocmenu.bind(this)} className="dropdown-2" isOpen={this.state.document_list_mb_open} toggle={this.toggledoc_mb_list.bind(this)}>
                                              <DropdownToggle className="justify-content-start" caret>
                                                <a className="inner-land-document">Document AI</a>
                                              </DropdownToggle>
                                              <DropdownMenu className="dropdown-menu-2 land-dropdown2">
                                                <Row>
                                                    <Col>
                                                        <ul className="">
                                                            <li>
                                                                <Link to="/document/classification">Document Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/document/us-driver-license">US Driver License</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/document/bank-state">Bank Statement</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/document/1003">1003 Form</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/document/1120">1120 Form</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/document/1120s">1120s Form</Link>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                              </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                    <li>
                                        <Link id="land-company-mb" to="/company">Company</Link>
                                    </li>
                                    <li>
                                        <div className="d-block" id="land-resources-mb">
                                            <Dropdown onMouseLeave={this.mb_mouseleaveresourcemenu.bind(this)} onMouseOver={this.mb_mouseoverresourcemenu.bind(this)} className="dropdown-2" isOpen={this.state.resource_list_mb_open} toggle={this.mb_toggleresource_list.bind(this)}>
                                              <DropdownToggle className="justify-content-start" caret>
                                                <a className="">Resources</a>
                                              </DropdownToggle>
                                              <DropdownMenu className="dropdown-menu-2 land-dropdown2">
                                                <Row>
                                                    <Col>
                                                        <ul className="list-unstyled document-sub-menu">
                                                            <li>
                                                                <a className="hand" href="https://docs.ellietrac.ai">Documentation</a>
                                                            </li>
                                                            <li>
                                                                <a className="hand" href="https://docs.ellietrac.ai/usecases/">Use Cases</a>
                                                            </li>
                                                            <li>
                                                                <a className="hand" href="https://blog.ellietrac.ai">Blog</a>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                              </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                </ul>
                                <Button onClick={() => {window.location.href = 'https://console.ellietrac.ai/login'}} className="btn l-login-btn"><i className="ri-user-line"></i><span>Login</span></Button>
                                <Button className="btn l-gd-btn"><span>Book Demo</span></Button>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, { })(withRouter(Header));
