import React, { Component } from "react";

class Alert2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.close_alert2 = this.close_alert2.bind(this);
    }
    close_alert2() {
        document.getElementById('alert2modal').style.display = 'none';
        document.getElementById('alert2msg').innerHTML = '';
        document.getElementById('warning2rect').style.display = 'none';
        document.getElementById('success2rect').style.display = 'none';
    }
    render() {
        return (
            <React.Fragment>
                <div className="tbindex" id="alert2modal">
                    <div className="">
                        <div className="modal fade show d-block" role="dialog">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="warning2rect" id="warning2rect">
                                                <span className="wn2ic1"></span>
                                                <span className="wn2ic2"></span>
                                            </div>
                                            <div className="success2rect" id="success2rect">
                                                <div className="sc2ic1"></div>
                                                <span className="sc2ic2"></span>
                                                <span className="sc2ic3"></span>
                                                <div className="sc2ic4"></div>
                                                <div className="sc2ic5"></div>
                                                <div className="sc2ic6"></div>
                                            </div>
                                            <span className="font-size-16" id="alert2msg"></span>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={this.close_alert2} type="button" className="btn btn-primary">OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Alert2;
