import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { publicRoutes, adminProtectedRoutes, landingRoutes, nfRoutes } from "./routes/";
import AppRoute from "./routes/route";
import NonAuthLayout from "./components/NonAuthLayout";
import AdminLayout from "./components/AdminLayout";
import LandingLayout from "./components/LandingPagesLayout";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";

import "./assets/scss/theme.scss";
import Alert2 from "./components/Common/Alert2";
import Loading from "./components/Common/Loading";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getLayout = this.getLayout.bind(this);
	}
    getLayout = () => {
		let layoutCls = VerticalLayout;

		switch (this.props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};
	render() {
	    const Layout = this.getLayout();

		return (
			<React.Fragment>
				<Router>
					<Switch>

						{adminProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={AdminLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isAdpAdmin={false}
								isPreprocessing={false}
							/>
						))}

						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isAdpAdmin={false}
								isPreprocessing={false}

							/>
						))}

						{landingRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={LandingLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isAdpAdmin={false}
								isPreprocessing={false}
								exact={route.exact}
							/>
						))}

						{nfRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isAdpAdmin={false}
								isPreprocessing={false}

							/>
						))}

					</Switch>
				</Router>
				<Alert2/>
				<Loading/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};


export default connect(mapStateToProps, null)(App);