import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED, REGISTER_USER_EMAIL_FAILED } from './actionTypes';

const initialState = {
    registrationError: null, user: null, loading: null, emailError: null, initialRender: true
};

const account = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,
                user: null,
                loading: true,
                registrationError: null,
                initialRender: true,
                emailError: null,
            };
            break;
       
        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                user: action.payload,
                loading: false,
                registrationError: null,
                initialRender: false,
                emailError: null
            };
            break;
        case REGISTER_USER_FAILED:
            state = {
                ...state,
                user: null,
                loading: false,
                registrationError: action.payload,
                initialRender: true,
                emailError: null
            };
            break;
        case REGISTER_USER_EMAIL_FAILED:
            state = {
                ...state,
                user: null,
                loading: false,
                emailError: action.payload,
                registrationError: null,
                initialRender: false
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;