import React, { Component } from "react";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import wt_text_logo from "../../assets/images/other-logos/white-with-text.png";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="land-footer">
                    <div className="f-box">
                        <img className="mb-5" src={wt_text_logo} />
                        <h4 className="white-color font-size-18 mb-3">ELLIETRAC is a platform that accelerate your lending business by automatically processing</h4>
                        <h4 className="white-color font-size-18 mb-4">mortgage and bank documents with AI.</h4>
                        <div className="f-divider"></div>
                        <h4 className="white-color font-size-18 mt-4">2022. All Rights Reserved. Ellietrac.ai</h4>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, { })(withRouter(Footer));
